import Music from "./Components/Music/Music.js";

function App() {
  return (
    <>
      <Music />
    </>
  );
}

export default App;